<template>
    
    <div class="user-register">
        <div>
            add new user
        </div>
    </div>
    
</template>

<script>
    import axios from 'axios';
import UserModel from '../../models/UserModel';

    export default {

        data: function(){
            return {
                newUser: new UserModel()
            }
        },
        methods: {
        },
        computed: {
        },
        created: function(){
            const $this = this;
            axios({
                method: 'get', 
                url: 'https://localhost:49155/Users/1',    
                headers: { 
                    'Content-Type': 'text/json',
                    'Authorization': 'bearer ' + $this.$store.state.jwt 
                },
            }).then(function(response){
                console.log(response.data);
            }, function(){
                console.log(arguments);
            });
        }
    }

</script>

<style lang="scss">
@import "../../assets/scss/variables.scss";
</style>